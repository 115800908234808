/* roadmap.css */

.gpt3__roadmap {
  width: 100%;
  text-align: left;
  padding-bottom: 20px; 
}

.gpt3__roadmap-content {
  margin-bottom: 30px; 
}

.gradient__text {
  font-family: var(--font-family);
  font-size: 32px; 
  width: 100%;
  line-height: 75px;
  letter-spacing: -0.04em;
  font-weight: 800;
  font-size: 62px;
}

.gpt3__roadmap-img {
  width: 100%;
}

.gpt3__roadmap-img img {
  width: 100%;
  aspect-ratio: 16 / 9; /* Modifica il rapporto larghezza/altezza secondo le tue esigenze */
}

@media (max-width: 768px) {
  
  .gradient__text {
    font-size: 28px; 
  }
}